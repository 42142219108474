import type { Context } from '@nuxt/types';
import { AxiosResponse, AxiosError } from 'axios';

import {
  UPDATE_WORDPRESS_AUTHENTICATION,
  ADD_ARCHIVE_DATA,
  ADD_SEO_BY_PATH,
  ADD_ADFOX_PARAMS_BY_PATH,
} from '~/constants';
import { getArticleFetchApi } from '~/utils/apiUtils';

function fetchSeo ({ route, app: { $wordpressApi }, query }: Context): Promise<any> {
  const { page = 1, limit = 30 } = query;
  return $wordpressApi.fetchSeo(route.path, page, limit)
    .then((resp: AxiosResponse) => {
      if (resp.status === 200) {
        return resp.data;
      }
      return null;
    }).catch(() => { });
}

export default function (ctx: Context): Promise<any> | undefined {
  const { store, route, app, error, redirect } = ctx;

  const pagesDataRoute = route.fullPath.replace(route.hash, '');
  const isLoadedArticlesForCurrentRoute = store.state.pagesData[pagesDataRoute];
  if (isLoadedArticlesForCurrentRoute) {
    return;
  }

  const { $utils } = app;

  const pageNumber = $utils.getActivePage(route);
  const fetchArticles = getArticleFetchApi(route, app).fetchArticles(route, pageNumber);
  const articlesWithSeoPromise = Promise.all([
    fetchArticles,
    fetchSeo(ctx),
  ]);

  return articlesWithSeoPromise.then(([resp, seo]) => {
    if (route.name === 'author-all' && resp.status === 200 && resp.data?.items?.[0]?.author?.integer_id) {
      // eslint-disable-next-line no-console
      console.warn('redirect to', resp.data.items[0].author.profile);
      redirect(301, resp.data.items[0].author.profile);
    }
    if (resp.status === 200) {
      const { items, totalPages, title, adfoxParams, isUserLoggedIn, type, id, icon, breadcrumb } = resp.data;
      const { commit } = store;
      commit(UPDATE_WORDPRESS_AUTHENTICATION, { newState: isUserLoggedIn });
      commit(ADD_ARCHIVE_DATA, {
        path: pagesDataRoute,
        data: {
          type,
          id,
          totalPages,
          articles: items,
          title: title || '',
          icon,
          breadcrumb,
        },
      });

      const seoCorrect = seo.title ? seo : resp.data.seo;

      commit(ADD_SEO_BY_PATH, { seo: seoCorrect, path: pagesDataRoute });
      commit(ADD_ADFOX_PARAMS_BY_PATH, { adfoxParams, path: pagesDataRoute });
    } else {
      // eslint-disable-next-line no-console
      console.error('fetchArchivePageData response status', resp.status);
      error({ statusCode: resp.status });
    }
  }).catch((err: AxiosError) => {
    if (err.response?.status === 301 && err.response?.data?.data?.location) {
      // eslint-disable-next-line no-console
      console.warn('redirect to 301', err.response?.data?.data?.location);
      redirect(301, err.response?.data?.data?.location);
    }
    const statusCode = err.response?.status ?? 500;
    // eslint-disable-next-line no-console
    console.error('fetchArchivePageData catch', { statusCode, message: err.toString() });
    error({ statusCode, message: err.toString() });
  });
}
