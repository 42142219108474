import type { Context } from '@nuxt/types';

import { REMOVE_AB_TITLES } from '~/constants';

export default function (context: Context) {
  const { store, from } = context;

  // если переходим на архивную страницу откуда-то
  if (from) {
    return store.commit(REMOVE_AB_TITLES);
  }
}
