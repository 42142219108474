import Vue from 'vue';
import { mapActions } from 'vuex';
import Cookies from 'js-cookie';

import { getDateText } from '@devhacker/shared/utils/date';
import {
  ADD_AB_TITLE_DATA,
  ADD_AB_TITLE_EVENT,
  GET_AB_TITLE_BY_ARTICLE,
  TEST_TITLE_KEY_PREFIX,
} from '~/constants';
import { getEditLinkHref, getRelativeLink, isHomeLink, isObject, random } from '~/utils';
import { FoldersState } from '~/store/folders';
import { ADS_LABELS, ADS_EXCEPTIONS } from '~/constants/ads-labels';

export default Vue.extend({
  computed: {
    articles (): Array<IArticle> {
      return [];
    },
  },

  methods: {
    ...mapActions([
      ADD_AB_TITLE_EVENT,
    ]),

    // TO DO: похожий метод в
    // src/containers/TaxonomyPostsContainer/TaxonomyPostsContainer.vue,
    // src/containers/ZenRotationContainer.vue
    // можно объединить, при этом ВАЖНО сохранить значения и набор свойств, тк они отличаются
    // в миксинах и в виджете, например title
    // В миксине выбирается тестовый заголовок и сохраняется в сторе если он есть.
    // А в виджете только выбирается сохраненный тестовый заголовок из стора если он
    // есть или основной заголовок. Плюс в миксине кроме этого метода есть еще другие,
    // которые не нужны в виджете
    $_mixin_getArticleProps (article: IWidgetArticle): {[propName: string]: any} {
      const {
        id,
        categories,
        author,
        url: articleUrl,
        img_alt,
        isAdvertising,
        isPromo,
        commentsEnabled,
        img,
        defaultImg,
        teaserUrl,
        type,
        advertLabel,
        adsToken,
        tags,
      } = article;
      const internalUrl = teaserUrl ? false : isHomeLink(articleUrl);
      const url = teaserUrl || (internalUrl ? getRelativeLink(articleUrl) : articleUrl);
      const title = this.getTitle(article);

      return {
        id,
        categories,
        isPromo,
        isAdvertising: isAdvertising && !ADS_EXCEPTIONS.includes(advertLabel),
        isTeaser: !!teaserUrl,
        commentsEnabled,
        internalUrl,
        url,
        title,
        images: article.image?.sizes || img,
        imgAlt: img_alt || article.image?.alt || article.title,
        tags,
        defaultImages: defaultImg,
        authorText: author?.name || '',
        authorUrl: author?.profile || '',
        inFavorites: (this.$store.getters.getArticleFavoriteId(id) > 0),
        dateText: getDateText(article.date),
        commentsCount: this.$store.getters.getCommentsCount(id),
        isAuthenticatedInWordpress: (this.$store.state as IRootState).isAuthenticatedInWordpress,
        editLinkHref: getEditLinkHref(id),
        viewsCount: this.$store.getters.getViewsCount(id),
        user: this.$store.state.user as IRootState['user'],
        folders: this.$store.state.folders as FoldersState,
        favorite: this.$store.getters.getArticleFavorite(id) as IFavoriteItem,
        favoriteMenuOffsets: {
          top: 48, // учитывать fixed шапку
        } as TOffsets,
        type,
        advertLabel,
        partnerText: ADS_LABELS[advertLabel],
        adsToken,
      };
    },
    getTitle (article: IWidgetArticle): string {
      const { id, title, abTitlesData } = article;

      // Если несколько заголовков
      // Подробнее см https://youtrack.lifehacker.ru/issue/LH-564
      if (isObject(abTitlesData?.titles)) {
        // если заголовок уже был выбран ранее
        const selectedAbTitle = this.$store.getters[GET_AB_TITLE_BY_ARTICLE](id);
        if (selectedAbTitle) {
          return selectedAbTitle;
        }

        // иначе выбор рандомного заголовка
        const max = Object.values(abTitlesData.titles).length;
        const index = random(max);
        const newAbTitle = abTitlesData.titles[String(index)];
        this.$store.commit(ADD_AB_TITLE_DATA, { id, title: newAbTitle });
        return newAbTitle;
      }

      return title;
    },

    hasAbTitlesData (abTitlesData: any): boolean {
      return isObject(abTitlesData?.titles);
    },

    onAbTitleEvent (article: IArticle, type: 'view' | 'click'): void {
      const { id, abTitlesData } = article;

      if (!this.hasAbTitlesData(abTitlesData)) {
        return;
      }

      const testTitle = this.$store.getters[GET_AB_TITLE_BY_ARTICLE](id);
      const isClickType = type === 'click';
      if (testTitle && isClickType) {
        this.saveTestTitleInCookie(id, testTitle);
      }

      const index = Object.values(abTitlesData.titles)
        .findIndex(item => (item === testTitle));

      if (index !== -1) {
        const data: AbTitleEvent = {
          index,
          event_type: type,
          post_id: id,
        };
        this.ADD_AB_TITLE_EVENT(data);
      }
    },

    onContextMenu (id: number): void {
      // При клике правой кнопкой мыши
      // сохранить тестовый заголовок в куки для того чтобы при открытии в новой вкладке достать и показать его
      // тк нет способа отследить открытие ссылки в новой вкладке из контекстного меню
      const testTitle = this.$store.getters[GET_AB_TITLE_BY_ARTICLE](id);
      if (testTitle) {
        this.saveTestTitleInCookie(id, testTitle);
      }
    },

    saveTestTitleInCookie (id: number, title: string): void {
      // Сохранить тестовый заголовок в куки для того чтобы при открытии в новой вкладке достать и показать его
      const inTenSeconds = new Date(new Date().getTime() + 10 * 1000);
      Cookies.set(TEST_TITLE_KEY_PREFIX + String(id), title, { expires: inTenSeconds });
    },
  },
});
