import Vue from 'vue';
import { pushBadge } from '~/components/Pusher/websockets-functions';

import { UPDATE_VIEWS_COUNTS } from '~/constants';

export default Vue.extend({
  data () {
    return {
      // Id статей количество просмотров которых сейчас обновляется
      loadingViewsIds: [] as number[],
    };
  },

  methods: {
    // Обновление количества просмотров статьи
    async $_mixin_updatingViewsCount_updateViewsCount (postId: number): Promise<void> {
      if (this.$_mixin_updatingViewsCount_getLoadingViews(postId)) {
        return;
      }

      try {
        this.loadingViewsIds.push(postId);
        // Метод отправляет данные в га и возвращает количество просмотров из него
        const { data: { viewsCount } } = await this.$viewsApi.syncArticleViewsCount(postId);
        const payload = {
          [postId]: viewsCount,
        };
        this.$store.commit(UPDATE_VIEWS_COUNTS, payload);
      } catch (error) {
        pushBadge(this.$store.dispatch, { content: 'Ошибка. Попробуйте еще раз.', isError: true });
        this.$sentry.captureException(error);
      } finally {
        this.loadingViewsIds = this.loadingViewsIds.filter(id => id !== postId);
      }
    },

    $_mixin_updatingViewsCount_getLoadingViews (postId: number): boolean {
      return this.loadingViewsIds.includes(postId);
    },
  },
});
